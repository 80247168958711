
export default {
  name: `ErrorPopup`,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit(`input`, val);
      }
    }
  }
};
